import { reactive } from "vue";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";

export const YmGoals = {
  RegistrationClick: 'tm3_reg_click',
  RegistrationGoal: 'tm3_reg_full',
  PlanClick: 'tm3_plan_click',
  PlanGoal: 'tm3_plan_full',
  OrderClick: 'tm3_order_click',
  OrderGoal: 'tm3_order_full',
  ConsultClick: 'tm3_consult_click',
  ConsultGoal: 'tm3_consult_full',
  ContactsClick: 'tm3_contacts_click',
} as const;

export type YmGoalType = typeof YmGoals[keyof typeof YmGoals];

const goals = reactive({
  regClick: true,
  planClick: true,
  orderClick: true,
  consultClick: true,
  contactsClick: true,
})

function isNeedSend(goal: YmGoalType) {
  switch (goal) {
    case YmGoals.RegistrationClick: return goals.regClick;
    case YmGoals.PlanClick: return goals.planClick;
    case YmGoals.OrderClick: return goals.orderClick;
    case YmGoals.ConsultClick: return goals.consultClick;
    case YmGoals.ContactsClick: return goals.contactsClick;
    default: return true;
  }
}

function updateGoalsList(goal: YmGoalType) {
  switch (goal) {
    case YmGoals.RegistrationClick:
      goals.regClick = false;
      break;
    case YmGoals.PlanClick:
      goals.planClick = false;
      break;
    case YmGoals.OrderClick:
      goals.orderClick = false;
      break;
    case YmGoals.ConsultClick:
      goals.consultClick = false;
      break;
    case YmGoals.ContactsClick:
      goals.contactsClick = false;
      break;
  }
}

export function sendMetrica(goal: YmGoalType) {

  const { isAuth } = useUserAccess();

  if (!isAuth.value && isNeedSend(goal)) {
    try {
      if (typeof ym !== "undefined") {
        console.log('reachGoal', goal)
        ym(39120155, 'reachGoal', goal);
        updateGoalsList(goal)
      } else console.log('ym undefined')
    } catch {
      console.log('ym undefined')
    }
  }
}
