const storage = reactive({
  scrollIntoTariff: false,
  scrollIntoContacts: false,
  openNewSearch: false,
  onLogin: false,
  onReload: false,
  ignoreBeforeFetch: false,
  redirect: '',
})

export function useServiceStorage() {
  return {
    storage,
  }
}
