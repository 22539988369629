import type {
  CreateGroupI,
  CreateTemplateI,
  LoginI,
  MarkViewedI,
  ResetPasswordI,
  UpdateTemplateI
} from "@/use/api/ApiInterface";
import type { AdminI, AuthorizedUserI, InviteUserI } from "@/stores/auth/UserInterface";
import type { OrderI } from "@/use/tenderService/TenderServiceInterface";
import type { LotDetailInterface } from "~/stores/search/LotItemInterface";

import useNotificationsStore from "@/stores/systemNotifications/useNotificationsStore";
import { logMessage } from "@/utils/logger/logger";
import { useUserAccess } from "~/use/userRoleAccess/useUserAccess";
import { useServiceStorage } from "~/use/serviceStorage/useServiceStorage";
import { checkAvailableForNonAuth, onLogout } from "@/utils/authorization/auth";
import {
  companiesFiltersToQuery,
  notificationFiltersToQuery,
  serviceFiltersToQuery,
} from "@/utils/formatter/filterFormatter";
import { Tabs } from "@/router/tabs";

function initApi() {
  const fetchApi = $fetch.create({
    baseURL: (useRuntimeConfig().BACK_URL || '') + '/api/',
    onResponseError(ctx) {
      if (ctx.response.status === 401) {
        onLogout()
        if (checkAvailableForNonAuth()) {
          if (location.pathname !== '/') {
            const { storage } = useServiceStorage();
            storage.redirect = location.pathname + location.search;
          }
          navigateTo({ name: Tabs.Landing });
        }
      } else if (ctx.response.status === 403) {
        const { isAuth } = useUserAccess()
        if (isAuth.value) {
          logMessage(JSON.stringify(ctx.response.json));
          useNotificationsStore().showError('Доступ к запрашиваемому ресурсу запрещен')
          ctx.error = ctx.response._data;
          return;
        }
      } else if ([404, 412, 500, 502, 504].includes(ctx.response.status)) {
        logMessage(JSON.stringify(ctx.response.json));
        ctx.error = ctx.response._data;
        console.log(ctx.response.status, ctx)
        return;
      }
    },
    headers: {
      Accept: "application/json",
      ContentType: "application/json",
    },
  });

  return {
    lots: {
      byTLot: (id: number) => fetchApi<LotDetailInterface>(`lot-cards/${ id }`),
      byGovRu: (lotNumber: string, govRuId: string) => fetchApi<LotDetailInterface>(`lot-cards/${ lotNumber }/${ govRuId }`)
    },
    apps: {
      postApp: (payload: any) => fetchApi('apps', { method: 'POST', body: payload }),
    },
    contracts: {
      postContract: (payload: any) => fetchApi('contracts', { method: 'POST', body: payload }),
      fetchContractDates: <T>(id: number) => fetchApi<T>(`contracts/${ id }/dates`),
      patchContractDateSign: <T>(id: number, payload: any) => fetchApi<T>(`contracts/${ id }/date-sign`, { method: 'PATCH', body: payload }),
    },
    comments: {
      postComment: <T>(payload: any) => fetchApi<T>('comments', { method: 'POST', body: payload }),
      patchComment: <T>(id: number, payload: any) => fetchApi<T>(`comments/${ id }`, { method: 'PATCH', body: payload }),
      deleteComment: (id: number) => fetchApi(`comments/${ id }`, { method: 'DELETE' }),
    },
    common: {
      patchLotInfo: (route: string, payload: any) => fetchApi(route, { method: 'PATCH', body: payload }),       // todo сделать что-то с тем, что роут формируется извне
      fetchSupplemental: (route: string, payload: any, signal: AbortSignal) => fetchApi(route, { method: 'POST', body: payload, signal }),        // todo сделать что-то с тем, что роут формируется извне
      fetchEntitiesList: <T>(route: string, payload: any, offset: number, signal?: AbortSignal) => fetchApi<T>(route, {
        headers: { "new-data-offset": String(offset) }, method: 'POST', body: payload, signal
      }),    // todo сделать что-то с тем, что роут формируется извне
    },
    analytics: {
      fetchCommon: <T>(route: string, payload: any, signal: AbortSignal) => fetchApi<T>(route, { method: 'POST', body: payload, signal }),
      fetchValidateFilters: (payload: any) => fetchApi('analytics/industry/criteria', { method: 'POST', body: payload }),
      fetchCompanyExist: (payload: any) => fetchApi('orgCompanies', { method: 'POST', body: payload }),
      fetchFilteredCompanies: (payload: any, signal: AbortSignal) => fetchApi(`/suggestions/api/4_1/rs/suggest/party`, {
        headers: {
          Accept: "application/json",
          ContentType: "application/json",
          Authorization: "Token e9390faf8d6ff07fc90c58e65f663f32165802ea"
        },
        method: 'POST', body: payload, signal,
      }),
    },
    /** получение списков данных для работы системы */
    manuals: {
      fetchRegions: () => fetchApi('regions'),
      fetchIndustries: () => fetchApi('industries'),
      fetchCurrencies: () => fetchApi('currencies'),
      fetchAppStatuses: () => fetchApi('apps-statuses'),
      fetchContractStatuses: () => fetchApi('contracts-statuses'),
      fetchServiceStatuses: () => fetchApi('orders-statuses'),
      fetchMailingSchedule: () => fetchApi('mailing-schedule'),
      fetchObjectTypes: () => fetchApi('object-types'),
      fetchSiteGroups: () => fetchApi('site-groups'),
      fetchRoles: () => fetchApi('roles'),
      fetchAdminsRoles: () => fetchApi('admins-roles'),
      fetchConsultingServices: () => fetchApi('consulting-services'),
      fetchDeliveryTypes: () => fetchApi('delivery-types'),
      fetchNotificationTypes: () => fetchApi('notification-types'),
      fetchTariffsList: () => fetchApi('tariffs'),
      fetchTariffsOptions: () => fetchApi('options'),
    },
    filtered: {
      fetchOkpd: () => fetchApi('okpd'),
      fetchOkpdChild: (payload: any) => fetchApi('okpd', { method: 'POST', body: payload }),    // todo в purchase objects используется fetchFilteredEntity
      fetchFilteredEntity: <T>(route: string, payload: any, signal?: AbortSignal) => fetchApi<T>(route, { method: 'POST', body: payload, signal }),      // todo сделать что-то с тем, что роут формируется извне
    },
    /** работа с файлами */
    files: {
      fetchFileDownload: (route: string, payload: any) => fetchApi(route, { method: 'POST', body: payload }),    // todo сделать что-то с тем, что роут формируется извне
      fetchFormCalculation: (id: number) => fetchApi(`calculations?lotId=${ id }`),
    },
    /** работа с шаблонами */
    searchTemplates: {
      fetchTemplates: (query: string) => fetchApi(`templates${ query }`),
      fetchGroups: (query: string) => fetchApi(`templates-groups${ query }`),
      fetchCountUnseen: <T>(query: string) => fetchApi<T>(`templates/lots/unseen${ query }`),
      fetchTemplateInfo: <T>(id: number, signal: AbortSignal) => fetchApi<T>(`templates/${ id }`, { signal }),
      postTemplate: (payload: CreateTemplateI) => fetchApi('templates', { method: 'POST', body: payload }),
      patchTemplate: (id: number, payload: UpdateTemplateI) => fetchApi(`templates/${id}`, { method: 'PATCH', body: payload }),
      deleteTemplate: (id: number) => fetchApi(`templates/${id}`, { method: 'DELETE' }),
      postGroup: <T>(payload: CreateGroupI) => fetchApi<T>('templates-groups', { method: 'POST', body: payload }),
      patchGroup: <T>(id: number, payload: CreateGroupI) => fetchApi<T>(`templates-groups/${id}`, { method: 'PATCH', body: payload }),
      deleteGroup: (id: number) => fetchApi(`templates-groups/${id}`, { method: 'DELETE' }),
      fetchMarkViewed: (payload: MarkViewedI) => fetchApi('templates/lots/unseen', { method: 'POST', body: payload }),
    },
    /** работа с пользователями */
    users: {
      fetchUsers: <T>(query: string) => fetchApi<T>(`users${ query }`),
      postUser: <T>(payload: InviteUserI) => fetchApi<T>('users', { method: 'POST', body: payload }),
      patchUser: <T>(id: number, payload: any) => fetchApi<T>(`users/${ id }`, { method: 'PATCH', body: payload }),
    },
    /** работа с компаниями */
    companies: {
      postCompany: (payload: any) => fetchApi('companies', { method: 'POST', body: payload }),
      fetchCompanyInfo: (id: number) => fetchApi(`companies/${ id }`),
      patchCompanyInfo: (id: number, payload: any) => fetchApi(`companies/${ id }`, { method: 'PATCH', body: payload }),
    },
    /** список изменений */
    releaseNotes: {
      getBackendNotes: <T>() => fetchApi<T>('versions'),
    },
    /** история уведомлений */
    notificationHistory: {
      fetchNotificationsList: <T>(filters: any) => fetchApi<T>(`notifications${ notificationFiltersToQuery(filters) }`, { headers: { Accept: "application/ld+json" } }),
    },
    /** тендерные услуги */
    orders: {
      fetchOrdersList: <T>(filters: any) => fetchApi<T>(`orders${ serviceFiltersToQuery(filters) }`, { headers: { Accept: "application/ld+json" } }),
      postOrder: (payload: OrderI) => fetchApi('orders', { method: 'POST', body: payload }),
      postOrderExecution: (payload: any) => fetchApi('order-executions', { method: 'POST', body: payload }),
      patchOrder: (id: number, payload: any) => fetchApi(`orders/${ id }`, { method: 'PATCH', body: payload }),
    },
    /** раздел "настройки" */
    settings: {
      users: {
        fetchNotificationsSettings: <T>(query: string) => fetchApi<T>(`settings/notifications${ query }`),
        patchNotificationsSettings: (payload: any) => fetchApi('settings/notifications', { method: 'POST', body: payload }),
        fetchInterfaceSettings: () => fetchApi('users/settings'),
        patchInterfaceSettings: (payload: any) => fetchApi('users/settings', { method: 'POST', body: payload }),
      },
      company: {
        fetchCompanySettings: <T>(query: string) => fetchApi<T>(`companies/settings${ query }`),
        patchCompanySettings: (payload: any) => fetchApi('companies/settings', { method: 'POST', body: payload }),
      },
    },
    tags: {
      fetchTenderTags: <T>(query: string) => fetchApi<T>(`tags${ query }`, { headers: { Accept: "application/ld+json" } }),
      postTenderTag: <T>(payload: any) => fetchApi<T>('tags', { method: 'POST', body: payload }),
      patchTenderTag: <T>(id: number, payload: any) => fetchApi<T>(`tags/${ id }`, { method: 'PATCH', body: payload }),
      deleteTenderTag: (id: number) => fetchApi(`tags/${ id }`, { method: 'DELETE' }),
      restoreTenderTag: (id: number) => fetchApi(`tags/${ id }`, { method: 'PATCH', body: { isDeleted: false } }),
      applyTenderTagToLot: (payload: any) => fetchApi('lot_tags', { method: 'POST', body: payload }),
      removeTenderTagFromLot: (id: number) => fetchApi(`lot_tags/${ id }`, { method: 'DELETE' }),
    },
    /** security */
    security: {
      getMe: () => fetchApi<AuthorizedUserI>('security/users/me'),
      fetchEmailExist: (email: string) => fetchApi(`security/email/check?email=${ email }`),
      resetPassword: (email: string) => fetchApi(`security/users/password/forgot?email=${ email }`),
      patchPassword: (id: number, payload: ResetPasswordI) => fetchApi(`security/users/${ id }`, { method: 'PATCH', body: payload }),
      patchLogin: (id: number, payload: any) => fetchApi(`security/users/${ id }`, { method: 'PATCH', body: payload }),
      fetchQuickLink: (id: number) => fetchApi(`security/users/${ id }/quick-link`),
      fetchLogin: (payload: LoginI) => fetchApi('login', { method: 'POST', body: payload }),
      fetchLogout: () => fetchApi('logout'),
    },
    /** для администрации сайта (сотрудников) */
    admin: {
      postAdmin: <T>(payload: any) => fetchApi<T>('admins', { method: 'POST', body: payload }),
      patchAdmin: <T>(id: number, payload: any) => fetchApi<T>(`admins/${ id }`, { method: 'PATCH', body: payload }),
      fetchAdminsList: () => fetchApi<AdminI[]>('admins'),
      fetchManagersCompanies: (filters: string) => fetchApi(`companies${ companiesFiltersToQuery(filters) }`, { headers: { Accept: "application/ld+json" } }),
      fetchManagersCompaniesByQuery: (query: string, signal: AbortSignal) => fetchApi(`companies${ query ? `?search=${ query }` : '' }`, { headers: { Accept: "application/ld+json" }, signal }),
      applyManagerToCompany: (payload: any) => fetchApi('companies-managers', { method: 'POST', body: payload }),
      applyCrmNumberToCompany: (companyId: number, payload: any) => fetchApi(`companies/${ companyId }`, { method: 'PATCH', body: payload }),
      removeManagerFromCompany: (id: number) => fetchApi(`companies-managers/${ id }`, { method: 'DELETE' }),
    },
  };
}

const cache: { api: ReturnType<typeof initApi> } = {
  api: null as any,
};

export function useApi() {
  if (!cache.api) cache.api = initApi();
  return cache.api;
}

export function useAbort() {
  const abortController = new AbortController();

  return {
    signal: abortController.signal,
    abort: () => abortController.abort(),
  };
}
