import { computed, ref } from "vue";
import { useApi } from "@/use/api/useApi";
import { defineStore } from "pinia";
import { getDefaultServiceList } from "@/utils/getters/manuals";
import type { Ref } from "vue";
import type { TenderServiceI } from "@/use/tenderService/TenderServiceInterface";
import type {
  StatusI,
  RegionI,
  CurrencyI,
  IndustryI,
  MailingTimeI,
  ContractStatusListI, BaseObjectI, SiteGroupI
} from "@/stores/manuals/ManualsInterface";

function updateData(manual: Ref<any>, data: any) {
  if (data) manual.value = data || []
}

function setRegions(manual: Ref<any>, data: any) {
  if (data) manual.value = data
    .map(country => {
      return {
        ...country,
        childes: country.childes?.map(district => {
          const id = Number((district.id as string)?.replace('district_', ''))
          return {
            ...district,
            districtId: id,
            childes: district.childes?.map(r => { return {...r, parentId: id }}),
          }
        }),
        firstLeaf: true,
      }
  })
}

function addMoscowTimezone(data: MailingTimeI[]) {
  return data.map(e => {
    return { ...e, time: e.time + ' МСК'}
  })
}

function formatOptions(rawTariffs: any) {
   return rawTariffs.map(e => ({
     id: e.id,
     title: e.title,
     isDeleted: e.isDeleted,
     options: e.optionGroups.reduce((res: any, item: any) => { res.push(...item.options); return res; }, []),
  }));
}

function traversal<T>(res: any[], tree: T, field: keyof T, key: keyof T, leafOnly: boolean) {
  if (!tree) return;
  const children = tree[field] || [];
  if (!children?.length) {
    res.push(tree[key])
    return;
  }
  if (!leafOnly) res.push(tree[key])
  children.forEach((c: T) => traversal(res, c, field, key, leafOnly));
}

function treeToIds(array: IndustryI[]) {
  const res : any[] = [];
  array.forEach(a => traversal<IndustryI>(res, a, 'children', 'id', false));
  return res;
}

function treeToLeafs(array: RegionI[]) {
  const res : any[] = [];
  array.forEach(a => traversal<RegionI>(res, a, 'childes', 'id', true));
  return res;
}

function treeToDistricts(array: RegionI[]) {
  let res : any[] = []
  array.forEach(country => res.push(...country.childes?.map(d => Number(d.id?.replace('district_', '')))))

  return res
}

export const lawsOptions = [
  { id: 1, title: 'Государственные закупки (44-ФЗ)', inputId: 'law_44' },
  { id: 2, title: 'Коммерческие закупки (223-ФЗ)', inputId: 'law_223' },
  { id: 4, title: '94-ФЗ', inputId: 'law_94' },
  { id: 5, title: '615-ПП', inputId: 'law_615' },
  { id: 7, title: 'Международные', inputId: 'law_in' },
  { id: 8, title: 'Реализация имущества', inputId: 'law_rid' },
  { id: 3, title: 'Прочие', inputId: 'law_other' },
];

export const lotStatusOptions = [
  { id: 1, title: 'Подача заявок', inputId: 'submission_applications' },
  { id: 2, title: 'Работа комиссии', inputId: 'commission_work' },
  { id: 3, title: 'Завершен', inputId: 'type_completed' },
  { id: 4, title: 'Отменен', inputId: 'type_canceled' },
]

export const typeLotOptions = [
  { id: 5, title: 'Электронный аукцион', inputId: 'electronic_auction' },
  { id: 4, title: 'Запрос котировок', inputId: 'request_for_quotations' },
  { id: 14, title: 'Запрос предложений', inputId: 'request_for_proposals' },
  { id: 2, title: 'Открытый конкурс', inputId: 'open_competition' },
  { id: 3, title: 'Открытый аукцион', inputId: 'open_auction' },
  { id: 9, title: 'Единственный поставщик', inputId: 'sole_supplier' },
  { id: 6, title: 'Предварительный отбор', inputId: 'sTenderType6' },
]

export const typeLotAdditionalOptions = [
  { id: 7, title: 'Закрытый конкурс', inputId: 'sTenderType7' },
  { id: 8, title: 'Закрытый аукцион', inputId: 'sTenderType8' },
  { id: 10, title: 'Конкурс с ограниченным участием', inputId: 'sTenderType10' },
  { id: 11, title: 'Закрытый конкурс с ограниченным участием', inputId: 'sTenderType11' },
  { id: 12, title: 'Запрос цен', inputId: 'sTenderType12' },
  { id: 13, title: 'Двухэтапный конкурс', inputId: 'sTenderType13' },
  { id: 15, title: 'Открытый конкурс в электронной форме', inputId: 'sTenderType15' },
  { id: 16, title: 'Открытый конкурс с ограниченным участием в электронной форме', inputId: 'sTenderType16' },
  { id: 17, title: 'Двухэтапный конкурс в электронной форме', inputId: 'sTenderType17' },
  { id: 18, title: 'Запрос предложений в электронной форме', inputId: 'sTenderType18' },
  { id: 19, title: 'Запрос котировок в электронной форме', inputId: 'sTenderType19' },
  { id: 1, title: 'Прочие', inputId: 'other' },
]

/** стор синхронизуется с localStorage самостоятельно (persistedstate) */
const useManualsStore = defineStore('manualsStore', () => {

  /** main */
  const regions = ref<RegionI[]>([]);
  const industries = ref<IndustryI[]>([]);
  const currencies = ref<CurrencyI[]>([]);
  const appStatuses = ref<StatusI[]>([]);
  const contractStatuses = ref<StatusI[]>([]);
  const serviceStatuses = ref<StatusI[]>([]);
  const mailingSchedule = ref<MailingTimeI[]>([]);
  const objectTypes = ref<BaseObjectI[]>([]);
  const siteGroups = ref<SiteGroupI[]>([]);

  /** other */
  const usersRolesList = ref<BaseObjectI[]>([]);
  const adminsRolesList = ref<BaseObjectI[]>([]);
  const tenderServices = ref<TenderServiceI[]>(getDefaultServiceList());

  /** additional */
  const deliveryTypes = ref<BaseObjectI[]>([]);
  const notificationTypes = ref<BaseObjectI[]>([]);

  /** tariffs */
  const tariffsList = ref<any[]>([]);
  const tariffsOptionsList = ref<any[]>([]);

  /** списки айдишников некоторых справочников */
  const computedRegionsIds = computed(() => treeToLeafs(regions.value))
  const computedDistrictsIds = computed(() => treeToDistricts(regions.value))
  const computedIndustryIds = computed(() => treeToIds(industries.value))

  const computedLotLawsIds = computed(() => lawsOptions.map(e => e.id))
  const computedLotStatusIds = computed(() => lotStatusOptions.map(e => e.id))
  const computedLotTypeShortIds = computed(() => [...typeLotOptions.map(e => e.id), ...typeLotAdditionalOptions.map(e => e.id)])
  const computedSiteGroupsIds = computed(() => siteGroups.value.map(e => e.code))

  const manuals = {
    getRegions: () => useApi().manuals.fetchRegions().then((response: any) => setRegions(regions, response)),
    getIndustries: () => useApi().manuals.fetchIndustries().then((response: any) => updateData(industries, response)),
    getCurrencies: () => useApi().manuals.fetchCurrencies().then((response: any) => updateData(currencies, response)),
    getAppStatuses: () => useApi().manuals.fetchAppStatuses().then((response: any) => updateData(appStatuses, response)).catch(() => {}),
    getContractStatuses: () => useApi().manuals.fetchContractStatuses().then((response: any) => updateData(contractStatuses, response)),
    getServiceStatuses: () => useApi().manuals.fetchServiceStatuses().then((response: any) => updateData(serviceStatuses, response)),
    getMailingSchedule: () => useApi().manuals.fetchMailingSchedule().then((response: any) => updateData(mailingSchedule, addMoscowTimezone(response || []))),
    getObjectTypes: () => useApi().manuals.fetchObjectTypes().then((response: any) => updateData(objectTypes, response)),
    getSiteGroups: () => useApi().manuals.fetchSiteGroups().then((response: any) => updateData(siteGroups, response)),

    getRoles: () => useApi().manuals.fetchRoles().then((response: any) => usersRolesList.value = response || []),
    getAdminsRoles: () => useApi().manuals.fetchAdminsRoles().then((response: any) => adminsRolesList.value = response || []),
    getTenderServices: () => useApi().manuals.fetchConsultingServices().then((response: any) => tenderServices.value = response || []),

    getDeliveryTypes: () => useApi().manuals.fetchDeliveryTypes().then((response: any) => updateData(deliveryTypes, response)),
    getNotificationTypes: () => useApi().manuals.fetchNotificationTypes().then((response: any) => updateData(notificationTypes, response)),

    getTariffsList: () => useApi().manuals.fetchTariffsList().then((response: any) => updateData(tariffsList, formatOptions(response))),
    getTariffsOptionsList: () => useApi().manuals.fetchTariffsOptions().then((response: any) => updateData(tariffsOptionsList, response)),
  }

  const contractStatusesGetter = computed<ContractStatusListI[]>(() => {
    return [
      { label: 'На заключении', tab: 'contractConclusion', options: contractStatuses.value.slice(0, 4) },
      { label: 'На исполнении', tab: 'contractExecution', options: contractStatuses.value.slice(4, 7) },
      { label: 'Завершенные', tab: 'contractCompleted', options: contractStatuses.value.slice(7) },
    ];
  })

  const tenderServicesGetter = computed<BaseObjectI[]>(() =>
    tenderServices.value?.reduce((res: any, item: any) => {
      if (item.consultingServices) res.push(...item.consultingServices);
      return res;
    }, [])
  );

  function resetStore(hard = false) {
    regions.value = []
    industries.value = []
    currencies.value = []
    appStatuses.value = []
    contractStatuses.value = []
    serviceStatuses.value = []
    mailingSchedule.value = []
    objectTypes.value = []
    siteGroups.value = []

    usersRolesList.value = []
    adminsRolesList.value = []
    if (hard) tenderServices.value = []

    deliveryTypes.value = []
    notificationTypes.value = []

    if (hard) tariffsList.value = []
    if (hard) tariffsOptionsList.value = []
  }

  return {
    regions,
    industries,
    currencies,
    appStatuses,
    contractStatuses,
    serviceStatuses,
    mailingSchedule,
    objectTypes,
    siteGroups,

    usersRolesList,
    adminsRolesList,
    tenderServices,

    deliveryTypes,
    notificationTypes,
    tariffsList,
    tariffsOptionsList,

    computedRegionsIds,
    computedDistrictsIds,
    computedIndustryIds,
    computedLotLawsIds,
    computedLotStatusIds,
    computedLotTypeShortIds,
    computedSiteGroupsIds,

    manuals,
    tenderServicesGetter,
    contractStatusesGetter,
    resetStore,
  }
}, { persist: true })

export default useManualsStore;
