<template lang="pug">
teleport(to="body")
  .modal-window(
    v-if="visible"
    :style="tempStylesWrapper"
    @mousedown.self="onModalClick()"
  )
    .modal-dialog(:style="tempStyles")
      .modal-dialog__header(v-if="$slots.header || header")
        slot(name="header") {{ header }}
        ui-icon(v-if="closable" clickable :icon="UiIconNames.Icon_CloseBold" @click="$emit('content:hide')")

      .modal-dialog__content
        slot(name="content")
      .modal-dialog__actions(v-if="$slots.actions")
        slot(name="actions")
</template>

<script lang="ts">
import { defineComponent, watch } from "vue";
import { useToggle } from "@/use/other/useToggle";

import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "UiDialog",
  components: {
    UiIcon,
  },
  props: {
    visibleProp: Boolean,
    width: String,
    height: String,
    header: String,
    zIndex: Number,
    /** показывать ли кнопку закрытия диалога */
    closable: {
      type: Boolean,
      default: false,
    },
    /** если modal = true, то закрыть можно только по кнопкам */
    modal: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "content:hide",
  ],
  setup(props, context) {

    const { visible, show, hide } = useToggle();

    if (props.visibleProp) show();

    watch(() => props.visibleProp, () => {
      props.visibleProp ? show() : hide()
    });

    const tempStyles: string =
      "z-index: " + props.zIndex + ";" +
      "width: " + props.width + "; " +
      "height: " + props.height + "; ";

    const tempStylesWrapper: string = "z-index: " + props.zIndex + ";";

    /** закрыть диалог при клике за его пределы (если передан modal = false) */
    function onModalClick() {
      if (!props.modal) hide();
    }

    return {
      tempStyles,
      tempStylesWrapper,
      visible,
      hide,
      onModalClick,
      UiIconNames,
    };
  },
});
</script>

<style scoped lang="scss">
$modal-dialog-background-color: rgba(70, 70, 70, 0.16);

.modal-window {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: $modal-dialog-background-color;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-dialog {
  background-color: white;
  z-index: 11;

  box-shadow: var(--dialog-border-shadow);
  border: 1px solid var(--main-color-grey-popup);
  border-radius: 4px;

  display: flex;
  flex-flow: column;
  gap: 16px;
  padding: 16px 24px;
  box-sizing: border-box;
  max-width: calc(100vw - 48px);
  max-height: calc(100vh - 96px);
}

.modal-dialog__header {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: var(--main-color-blue);
}

.modal-dialog__content {
  display: flex;
  flex-flow: column;
  gap: 16px;
  overflow: auto;
}

.modal-dialog__actions {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  justify-content: right;
}
</style>
